<template>
  <div class="card text-center" no-body v-if="show">
    <div class="col-12" v-if="show">
    <h3 class="row_title">{{caption}}</h3>
    <div class="row text-center">
      <div class="footer m-auto">
        <ul class="footer_list" >
          <li>
            <div class="col-12 col-sm-12" v-for="(subType, inx_subType) in subTypeList" :key="'T'+inx_subType">
              <h4><a href="#" @click="ev => expandSubType(inx_subType)"><li :class="'static-subtype-item'+shouldCollapsed(inx_subType)">{{subTypeName(subType)}}</li></a></h4>
              <div v-if="collapsed[inx_subType]"></div>
              <div v-else>
                <div class="col-12 col-sm-12" v-for="(item, inx) in getItemListWithSubType(subType[0])" :key="'I'+inx">
                  <a class="footer-item" :href="item[0]"> {{item[1]}}</a>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import TemplateFooterTreeColumnMobile from './TemplateFooterTreeColumnMobile';

export default {
  name: 'footer-tree-column-mobile',
  mixins: [TemplateFooterTreeColumnMobile],
};
</script>

<style scoped>
header button.btn {
  text-align: inherit;
}
.card .card-header .btn-header-link.collapsed:after {
  content: '\f107';
}
.card .card-header .btn-header-link:after {
  content: '\f106';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  float: left;
}
.card {
  margin-bottom: 5px;
}
.card-body, .card .card-header .btn-header-link{
  background: #0161ab;
}
a.footer_item{
  color: #e9e9e9 !important;
  text-decoration: none !important;
  font-weight: 300;
  transition: 0.5s;
}
a {
  color: #007bff;
  text-decoration: none !important;
  background-color: transparent;
}
.footerarea .footer_body .card {
  background-color: unset;
  border: unset;
}
.footerarea .footer_body .card h4 a li{
  font-size: 16px;
  font-weight: bold;
}
</style>
